import React, { useCallback, useEffect } from "react";
import { useStoreContext } from "../../../context/StoreContext";
import { v2Api } from "../../../api/v2.api";
import SantaCard from "./SantaCard";
import { FaAngleLeft } from "react-icons/fa6";

//  const [states, setStates] = useState({
//    ...
//     santasDetails: {
//       santas: [],
//       isLoading: false,
//     },
//   });

export default function SantaPresentationCont({ states, setStates }) {
  const {
    storeStates: { universalFilter, hubsByCity },
  } = useStoreContext();

  useEffect(() => {
    santaListFetch();

    // eslint-disable-next-line
  }, [universalFilter.city, universalFilter.hub]);

  const santaListFetch = useCallback(async () => {
    if (universalFilter.city === "all") {
      return;
    }

    //  isLoading true
    setStates((p) => ({
      ...p,
      santasDetails: {
        ...p.santasDetails,
        isLoading: true,
      },
    }));

    try {
      let hubIds = [];

      if (universalFilter.hub === "all" && universalFilter.city !== "all") {
        hubIds = hubsByCity
          ?.find((city) => city.city === universalFilter.city)
          ?.hubs?.map?.((hub) => hub._id);
      } else if (universalFilter.hub !== "all") {
        hubIds = [universalFilter.hub];
      }

      const res = await v2Api({
        tags: ["santa"],
        operation: "getSantaListForSantaListingPage",
        data: {
          city: universalFilter?.city,
          hubs: hubIds,
        },
      });
      if (res.isSuccess) {
        setStates((p) => ({
          ...p,
          santasDetails: {
            ...p.santasDetails,
            santas: res.santas,
          },
        }));
      } else {
        window.toast(res.message, "error");
      }
    } catch (error) {
      console.error(error);
      window.toast("Something went wrong!", "error");
    }

    // isLoading false
    setStates((p) => ({
      ...p,
      santasDetails: {
        ...p.santasDetails,
        isLoading: false,
      },
    }));
  }, [universalFilter, hubsByCity, setStates]);

  const getPointForSanta = (santa) => {
    const isLocationavailable =
      states.santaPointType === "lastLogin"
        ? santa?.lastLogin?.location?.lat
        : santa?.address?.currentAddress?.lat;

    if (isLocationavailable) {
      return {
        lat:
          states.santaPointType === "lastLogin"
            ? santa?.lastLogin?.location?.lat
            : santa?.address?.currentAddress?.lat,
        lng:
          states.santaPointType === "lastLogin"
            ? santa?.lastLogin?.location?.lng
            : santa?.address?.currentAddress?.lng,
        santaId: santa._id,
        onPointClick: () => {
          setStates((p) => ({
            ...p,
            selectedSanta: santa,
            isSideContainerOpen: true,
            isSantaListPresent: false,
          }));
        },
        title: santa?.personalDetails?.name?.split?.(" ")?.[0] || "",
        color: "#2136d4",
        text: "ebo santa",
      };
    }

    return null;
  };

  return (
    <div className="flex-col w-full bg-white rounded-lg max-h-[35rem] border border-gray-200 p-4">
      {states?.santasDetails?.selectedSanta ? (
        <div
          onClick={() => {
            setStates((p) => ({
              ...p,
              santasDetails: {
                ...p.santasDetails,
                selectedSanta: null,
              },
            }));
          }}
          className="w-full cursor-pointer text-gray-600 mb-2 font-medium flex items-center gap-1 text-base"
        >
          <FaAngleLeft />
          Back to santa list
        </div>
      ) : (
        <div className="w-full text-gray-500 mb-2 font-medium text-base">
          ebo Santas{" "}
          {states.santasDetails?.santas?.length > 0 && (
            <> ({states.santasDetails?.santas?.length})</>
          )}
        </div>
      )}

      {universalFilter?.city !== "all" ? (
        <>
          {states.santasDetails?.isLoading ? (
            <div className="flex-col w-full space-y-4 overflow-scroll">
              {[1, 2, 3, 4, 5].map?.((_santa, i) => {
                return (
                  <div
                    key={i}
                    className="skeleton w-full rounded-lg h-[12rem] flex-shrink-0"
                  ></div>
                );
              })}
            </div>
          ) : (
            <>
              {!states?.santasDetails?.selectedSanta ? (
                <div className="flex-col w-full space-y-4 overflow-scroll">
                  {states.santasDetails?.santas?.map?.((_santa, i) => {
                    return (
                      <div
                        key={i}
                        onClick={() => {
                          const point = getPointForSanta(_santa);

                          if (!point) {
                            window.toast(
                              "Santa's location is not available",
                              "error"
                            );
                          }
                          setStates((p) => ({
                            ...p,
                            santasDetails: {
                              ...p.santasDetails,
                              selectedSanta: _santa,
                            },
                            map: {
                              ...p.map,
                              focusPoint: {
                                lat: point.lat,
                                lng: point.lng,
                              },
                            },
                          }));
                        }}
                        className="cursor-pointer"
                      >
                        <SantaCard
                          santa={_santa}
                          statsPreview={false}
                          selectAllHubs={() => {
                            setStates((p) => ({
                              ...p,
                              selectedHub: "all",
                            }));
                          }}
                          viewProfileButton={false}
                        />
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="w-full">
                  <SantaCard
                    santa={states.santasDetails.selectedSanta}
                    selectAllHubs={() => {
                      setStates((p) => ({
                        ...p,
                        selectedHub: "all",
                      }));
                    }}
                  />
                </div>
              )}
            </>
          )}
        </>
      ) : (
        <div className="flex-center w-full h-[5rem] text-gray-500 ">
          Select any city or hub to view the list santas!
        </div>
      )}
    </div>
  );
}

import { memo, useEffect, useRef } from "react";

const loadScript = (src, id, callback) => {
  if (window.mapkit) {
    callback();
    return;
  }

  const existingScript = document.getElementById(id);

  if (!existingScript) {
    const script = document.createElement("script");
    script.src = src;
    script.id = id;
    script.onload = callback;
    document.body.appendChild(script);
  } else {
    existingScript.onload = callback;
  }
};

const AppleMapWithPolygons = ({
  points,
  focusPoint,
  polygons = [],
  shouldPolygonsPresent = true,
  defaultOrigin = {
    // navi mumbai coordinates
    lat: 19.04360522319033,
    lng: 73.07177922952339,
  },
  coordinateSpan = { latitudeDelta: 0.1, longitudeDelta: 0.1 },
  onMapClick = (point) => {},
}) => {
  // focusPoint is the point to focus on whenever we get a valid point as focusPoint

  const mapRef = useRef(null);
  const mapInstance = useRef(null); // Store the map instance
  const annotationsRef = useRef([]); // Store the annotations
  const mapInitialized = useRef(false); // To track if the map is initialized
  const polygonsRef = useRef([]);

  useEffect(() => {
    loadScript(
      "https://cdn.apple-mapkit.com/mk/5.x.x/mapkit.js",
      "mapkit-js",
      initMap
    );

    return () => {
      // Only remove the script if it was added by this component
      const script = document.getElementById("mapkit-js");
      if (script && !window.mapkit) {
        document.body.removeChild(script);
      }
      if (mapInitialized.current) {
        mapInstance.current.removeEventListener("single-tap", handleMapClick);
      }
    };

    // eslint-disable-next-line
  }, [points, focusPoint, shouldPolygonsPresent]);

  const initMap = () => {
    const token = process.env.REACT_APP_APPLE_MAPKIT_TOKEN;
    if (!token) {
      console.error("Apple MapKit JS token is missing.");
      return;
    }
    if (!mapInitialized.current) {
      window.mapkit.init({
        authorizationCallback: (done) => {
          done(token);
        },
      });

      mapInstance.current = new window.mapkit.Map(mapRef.current);
      mapInstance.current.region = new window.mapkit.CoordinateRegion(
        new window.mapkit.Coordinate(
          points?.[0]?.lat || defaultOrigin.lat,
          points?.[0]?.lng || defaultOrigin.lng
        ),
        new window.mapkit.CoordinateSpan(
          coordinateSpan.latitudeDelta,
          coordinateSpan.longitudeDelta
        )
      );

      mapInitialized.current = true; // Mark the map as initialized
    }

    updateAnnotations({ points, mapInstance, annotationsRef, focusPoint });
    updatePolygons({
      polygons: shouldPolygonsPresent ? polygons : [],
      mapInstance,
      polygonsRef,
    }); // Update the polygons

    mapInstance.current.addEventListener("single-tap", handleMapClick);
  };

  const handleMapClick = (event) => {
    // Extract the DOM point (x, y)
    const point = new window.DOMPoint(event.pointOnPage.x, event.pointOnPage.y);

    // Convert the DOM point to geographic coordinates (lat/lng)
    const mapCoordinate =
      mapInstance.current.convertPointOnPageToCoordinate(point);

    // Execute the onMapClick callback with the clicked point details
    onMapClick({ lat: mapCoordinate.latitude, lng: mapCoordinate.longitude });
  };

  return <div ref={mapRef} style={{ width: "100%", height: "100%" }} />;
};

const updatePolygons = ({ polygons, mapInstance, polygonsRef }) => {
  if (mapInstance.current) {
    // Remove existing polygons
    polygonsRef.current.forEach((polygon) => {
      mapInstance.current.removeOverlay(polygon);
    });
    polygonsRef.current = [];

    // Add new polygons
    const newPolygons = polygons.map((polygon) => {
      const coordinates = polygon.coordinates.map((coordSet) =>
        coordSet.map((coord) => {
          return new window.mapkit.Coordinate(coord[1], coord[0]);
        })
      );

      const style = new window.mapkit.Style({
        strokeColor: polygon.hubDetails?.[0]?.borderColor || "#2136d4", // Border color for the polygon,
        fillColor: polygon.hubDetails?.[0]?.bgColor || "#2136d4", // Fill color for the polygon
      });

      const overlay = new window.mapkit.PolygonOverlay(coordinates, {
        style: style,
      });

      polygonsRef.current.push(overlay);
      return overlay;
    });

    mapInstance.current.addOverlays(newPolygons);
  }
};

const updateAnnotations = ({
  points,
  mapInstance,
  annotationsRef,
  focusPoint,
}) => {
  if (mapInstance.current) {
    // Remove existing annotations
    mapInstance.current.removeAnnotations(annotationsRef.current);

    // Clear the references
    annotationsRef.current = [];

    // Add new annotations
    const newAnnotations = points.map((point) => {
      const coordinate = new window.mapkit.Coordinate(point.lat, point.lng);
      const annotation = new window.mapkit.MarkerAnnotation(coordinate, {
        title: point?.title || "",
        glyphText: point?.text || "e",
        color: point?.color || "#2136d4",
      });

      // Check if the point is the focus point
      if (
        focusPoint &&
        point.lat === focusPoint.lat &&
        point.lng === focusPoint.lng
      ) {
        // Apply styles for the focused annotation
        annotation.selected = true; // Set as selected
      }
      // Add click event listener
      annotation.addEventListener("select", (e) => {
        // only for non-focused points
        point?.onPointClick?.();
      });
      annotationsRef.current.push(annotation);
      return annotation;
    });

    mapInstance.current.addAnnotations(newAnnotations);

    // Optionally, update the map's region to center around the new points
    if (focusPoint) {
      mapInstance.current.region = new window.mapkit.CoordinateRegion(
        new window.mapkit.Coordinate(focusPoint.lat, focusPoint.lng),
        new window.mapkit.CoordinateSpan(0.1, 0.1) // Smaller span for closer zoom
      );
    } else if (points.length > 0) {
      mapInstance.current.region = new window.mapkit.CoordinateRegion(
        new window.mapkit.Coordinate(points[0].lat, points[0].lng),
        new window.mapkit.CoordinateSpan(0.1, 0.1)
      );
    }
  }
};

// export with memo
export default memo(AppleMapWithPolygons);

import React, { useEffect } from "react";
import { useStoreContext } from "../../../context/StoreContext";
import { Swiper, SwiperSlide } from "swiper/react";
import ProductCard from "./ProductCard";
import { MdEdit } from "react-icons/md";

export default function ProductSelectSection({ states, setStates }) {
  const {
    storeStates: { decorCategoryList, defaultDecorDiscount }, // [ { categoryName , code ... } ]
    setStoreStates,
  } = useStoreContext();

  // useEffect to fetch products on initial render and on filters change or search text change, with a debounce of 500ms
  useEffect(() => {
    const timer = setTimeout(() => {
      fetchProducts({ states, setStates });
    }, 500);

    return () => clearTimeout(timer);
    // eslint-disable-next-line
  }, [
    states.productSelection.searchText,
    states.productSelection.filters.categories,
    states.productSelection.filters.isVariantsPreview,
  ]);

  return (
    <div className="flex-col w-full bg-white rounded-lg border border-gray-200 p-4">
      <div className="w-full text-gray-500 mb-2 font-medium text-base">
        Product selection{" "}
        {states.productSelection.products?.length > 0 && (
          <>({states.productSelection.products?.length})</>
        )}
      </div>

      <input
        type="text"
        value={states?.productSelection?.searchText}
        onChange={(e) => {
          setStates((p) => ({
            ...p,
            productSelection: {
              ...p.productSelection,
              searchText: e.target.value,
            },
          }));
        }}
        placeholder="Product name | ID | tags"
        className="w-full p-2 border text-sm border-gray-300 rounded-xl"
      />
      {/* variants preview check box and categories selection select tag, in horizontal flex between UI */}
      <div className="flex items-center justify-between w-full mt-2">
        <div className="flex items-center gap-2">
          <input
            type="checkbox"
            checked={states.productSelection.filters.isVariantsPreview}
            onChange={(e) => {
              setStates((p) => ({
                ...p,
                productSelection: {
                  ...p.productSelection,
                  filters: {
                    ...p.productSelection.filters,
                    isVariantsPreview: e.target.checked,
                  },
                },
              }));
            }}
            className="cursor-pointer"
          />
          <div className="text-gray-500 text-sm font-medium">Show variants</div>
        </div>
        <select
          value={states.productSelection.filters.categories}
          onChange={(e) => {
            setStates((p) => ({
              ...p,
              productSelection: {
                ...p.productSelection,
                filters: {
                  ...p.productSelection.filters,
                  categories: e.target.value,
                },
              },
            }));
          }}
          className="p-1 px-2 capitalize bg-blue-50 rounded-lg text-xs md:text-sm border border-blue-300"
        >
          <option value="">All categories</option>
          {/* options of the categories and value selection of code */}
          {decorCategoryList.map((category) => (
            <option key={category.code} value={category.code}>
              {category.categoryName}
            </option>
          ))}
        </select>
      </div>

      <div className=" gap-1 flex items-center text-2xs md:text-sm text-[gray] ">
        <input
          type="checkBox"
          checked={defaultDecorDiscount.isEnabledDiscount}
          onChange={(e) =>
            setStoreStates((p) => ({
              ...p,
              defaultDecorDiscount: {
                ...p.defaultDecorDiscount,
                isEnabledDiscount: e.target.checked,
              },
            }))
          }
        />
        <p className="text-[gray] ">Enable discount</p>

        <MdEdit
          onClick={() => {
            setStoreStates((p) => ({
              ...p,
              defaultDecorDiscount: {
                ...p.defaultDecorDiscount,
                isEnableDiscountEditModal: true,
              },
            }));
          }}
          className=" text-[1rem] text-[#2136d4] cursor-pointer ml-1 "
        />
      </div>

      {states.productSelection.isLoading ? (
        <div className="w-full mt-4">
          <Swiper spaceBetween={10} className="w-full">
            {[1, 2, 3, 4, 5].map((i) => (
              <SwiperSlide key={i} className="!w-auto">
                <div
                  key={i}
                  className="w-[11rem] h-[14rem] skeleton rounded-lg"
                ></div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      ) : (
        <div className="w-full mt-4">
          <Swiper spaceBetween={10} className="w-full">
            {states.productSelection.products?.map?.((product) => (
              <SwiperSlide key={product._id} className="!w-auto">
                <ProductCard {...{ product }} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
    </div>
  );
}

// function to products fetch on search text change or filters change with a debounce of 500ms
const fetchProducts = async ({ states, setStates }) => {
  let query = {};

  // $or condition add if search text is not empty
  if (states.productSelection.searchText) {
    query.$or = [
      {
        name: { $regex: states.productSelection.searchText, $options: "i" },
      },
      {
        _id: {
          $regex: states.productSelection.searchText,
          $options: "i",
        },
      },
      // treat the text as comma seperated tags
      {
        tags: { $in: states.productSelection.searchText.split(",") },
      },
    ];
  }

  // ocassionCategory filter add if any selected and the selected is a number
  const parsedCategory = parseInt(
    states?.productSelection?.filters?.categories
  );
  if (!isNaN(parsedCategory)) {
    query.ocassionCategory = parsedCategory;
  }

  // don't fetch the products having 'variant' in their tags if variants preview is not selected
  if (!states.productSelection.filters.isVariantsPreview) {
    query.tags = { $nin: ["variant"] };
  }

  window.dbGet({
    collection: "product",
    query,
    onInitiate: () => {
      setStates((p) => ({
        ...p,
        productSelection: {
          ...p.productSelection,
          isLoading: true,
        },
      }));
    },
    projectionString:
      "_id listingPriceHubWise name images tags ocassionCategory",
    sortingObj: {
      ocassionCategoryIndex: 1,
    },
    onError: (e) => {
      setStates((p) => ({
        ...p,
        productSelection: {
          ...p.productSelection,
          isLoading: false,
        },
      }));
      window.toast(e, "error");
    },
    onSuccess: (res) => {
      console.log("products fetched", res);
      setStates((p) => ({
        ...p,
        productSelection: {
          ...p.productSelection,
          products: res.products,
          isLoading: false,
        },
      }));
    },
  });
};

// const [states, setStates] = useState({
//     placeSearch: {
//       placeSearchBarText: "", // place search bar text
//       predictions: null, // predictions fetched from google
//       isPredictionLoading: false, // is prediction loading
//       lat: "", // latitude of selected prediction
//       lng: "", // longitude of selected prediction
//       selectedPrediction: null, // selected prediction
//     },
//     map: {
//       points: [],
//       focusPoint: null,
//     },
//     productSelection: {
//       searchText: "",
//       isLoading: false,
//       selectedProduct: null,
//       products: [],
//       filters: {
//         categories: '',
//         isVariantsPreview: false
//       },
//     },
//   });

import React, { useState } from "react";
import { FaRegCopy, FaStar } from "react-icons/fa";
import { Link } from "react-router-dom";
import moment from "moment";
import { ImBlocked } from "react-icons/im";
import {
  MdErrorOutline,
  MdOpenInNew,
  MdOutlinePendingActions,
} from "react-icons/md";
import { formatCurrency } from "../../../utils/adminUtils";
import { Swiper, SwiperSlide } from "swiper/react";
import { BiCheck } from "react-icons/bi";

export default function SantaCard({
  santa,
  statsPreview = true,
  viewProfileButton = true,
}) {
  const personalDetails = santa?.personalDetails || {};

  const [states, setStates] = useState({
    presentedStats: "thisWeek", // today , upcoming
    isLoading: false,
  });

  return (
    <div
      className={`relative flex-col border border-gray-200 pb-4 shadow items-start bg-[white] rounded-xl `}
    >
      <div className="w-full flex-col flex pt-3 lg:pt-4 ">
        <div className="flex items-start px-3 lg:px-4 w-full justify-between">
          <div className="w-full items-center flex ">
            <img
              onClick={() => {
                window.showMsg(
                  personalDetails?.image || "/images/santaIcon.png",
                  "img"
                );
              }}
              src={personalDetails?.image || "/images/santaIcon.png"}
              alt={"santa"}
              className=" rounded-lg bg-blue-50 aspect-square w-[3rem] "
            />
            <div className="w-full pl-2 flex flex-col text-[.875rem] max-w-full overflow-hidden font-[500] ">
              <div className="w-full  flex font-[500] items-center overflow-scroll whitespace-nowrap ">
                <p>{personalDetails.name || "-"} </p>
              </div>

              <p className="flex items-center text-xs ml-1 text-gray-500 gap-1 ">
                {personalDetails.phoneNumber}
                <FaRegCopy
                  onClick={() => {
                    window.copyContent(
                      personalDetails.phoneNumber,
                      "Phone number Copied"
                    );
                  }}
                  className=" cursor-pointer text-[10px]"
                />
              </p>
              <div className="text-xs text-gray-500">
                Joined on:{" "}
                {moment(
                  santa?.personalDetails?.dateOfJoining || santa.createdAt
                ).format("Do, MMM YYYY")}
              </div>
            </div>
          </div>
          <div className="mt-2 ">
            <StatusMark santa={santa} />
          </div>
        </div>

        <div className="w-full px-3 lg:px-4 mt-2 ">
          <Tags santa={santa} />
        </div>

        <div className="w-full flex-col px-4 mt-1">
          <div className="  text-sm font-[500]">
            {santa?.address?.currentAddress?.locationLine1}
          </div>
          <div className="text-xs first-letter:capitalize ">
            {santa?.address?.currentAddress?.address + ", "}
            {santa?.address?.currentAddress?.locationLine2}
          </div>
        </div>

        {statsPreview && (
          <>
            <div className="py-1 px-3 lg:px-4  border-t border-gray-200 pt-3 max-w-full w-auto whitespace-nowrap  mt-2 ">
              <Swiper
                spaceBetween={10}
                slidesPerView="auto"
                freeMode={true}
                grabCursor={true}
                className=""
              >
                {[
                  {
                    label: "This week",
                    value: "thisWeek",
                  },
                  {
                    label: "Today",
                    value: "today",
                  },
                  {
                    label: "Upcoming",
                    value: "upcoming",
                  },
                ].map((curr, idx) => (
                  <SwiperSlide key={idx} className=" !w-auto ">
                    <div
                      onClick={() => {
                        setStates((p) => ({
                          ...p,
                          presentedStats: curr.value,
                        }));
                      }}
                      className={` ${
                        states.presentedStats === curr.value
                          ? "blue-gradient"
                          : "border border-gray-400 bg-gray-50 text-gray-600"
                      } flex-shrink-0 rounded-full shadow px-4 py-1.5 flex-col `}
                    >
                      <div className=" first-letter:capitalize font-medium text-2xs md:text-xs ">
                        {curr.label}
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
            {states.presentedStats === "thisWeek" && (
              <SantaStats santa={santa} />
            )}
            {states.presentedStats === "today" && <TodaysStats santa={santa} />}
            {states.presentedStats === "upcoming" && (
              <UpcomingStats santa={santa} />
            )}
          </>
        )}
      </div>
      {viewProfileButton && (
        <div className="w-full flex items-center  gap-4 px-4 pt-4">
          <Link
            to={`/team/santa/profile/${santa?._id}`}
            className=" flex-1 rounded-lg  px-4 py-3 gap-1 flex-center cursor-pointer blue-gradient text-xs md:text-sm font-medium "
          >
            View profile <MdOpenInNew />
          </Link>
        </div>
      )}
    </div>
  );
}

const Tags = ({ santa }) => {
  return (
    <div className="flex items-center gap-1.5 flex-shrink-0 whitespace-nowrap flex-wrap">
      {[
        { label: santa?.workArea?.city || "No city", theme: "blue-gradient" },
        {
          label:
            "in " + (santa?.workArea?.hubs?.length || 0) + " Hubs" || "No city",
          theme: "blue",
        },
      ].map((curr, idx) => {
        let tagTheme = "bg-blue-100 text-[#2136d4]";

        if (curr.theme === "blue-gradient") {
          tagTheme = "blue-gradient ";
        }
        return (
          <div
            key={idx}
            className={` ${tagTheme} px-2 py-0.5 rounded text-2xs md:text-xs`}
          >
            {curr.label}
          </div>
        );
      })}
    </div>
  );
};

const TodaysStats = ({ santa }) => {
  const ordersCount = santa?.todayOrders;
  const ordersValue = santa?.todayOrdersValue;
  const payout = santa?.todayOrdersPayout;

  return (
    <div className="w-full px-3 lg:px-4 flex-col  mt-2 ">
      <div className="flex items-center justify-between w-full ">
        <div className="  text-2xs px-2 py-0.5 bg-blue-100 rounded text-[#2136d4]">
          Today
        </div>
      </div>
      <div className=" flex-wrap whitespace-nowrap gap-y-3 gap-x-6 w-full rounded-lg mt-2 flex items-center ">
        {[
          {
            label: "Orders",
            value: ordersCount,
          },
          {
            label: "Revenue",
            value: formatCurrency(ordersValue),
          },
          {
            label: "Payout",
            value: formatCurrency(payout),
          },
        ].map((curr, idx) => {
          return (
            <div className="flex-col flex-1 gap-1 pr-4 pl-1 " key={idx}>
              <div className={`text-2xs lg:text-xs text-gray-600 `}>
                {curr.label}
              </div>
              <div className={`flex gap-1.5 items-center text-sm lg:text-base`}>
                <div className="  font-bold  ">{curr.value}</div>
                {curr.icon}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const UpcomingStats = ({ santa }) => {
  const upcomingOrders = santa.acceptedOrderRequests?.length || 0;

  return (
    <div className="w-full px-3 lg:px-4 flex-col  mt-2 ">
      <div className=" flex-wrap whitespace-nowrap gap-y-3 gap-x-6 w-full rounded-lg flex items-center">
        {[
          {
            label: (
              <div className="flex items-center gap-1">
                <div>Upcoming orders</div>
                <div className="text-2xs px-2 py-0.5 bg-blue-100 rounded text-[#2136d4]">
                  All time
                </div>
              </div>
            ),
            value: upcomingOrders,
          },
        ].map((curr, idx) => {
          return (
            <div className="flex-col flex-1 gap-1 pr-4 pl-1" key={idx}>
              <div className={`text-2xs lg:text-xs text-gray-600 `}>
                {curr.label}
              </div>
              <div className={`flex gap-1.5 items-center text-sm lg:text-base`}>
                <div className="  font-bold  ">{curr.value}</div>
                {curr.icon}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const SantaStats = ({ santa }) => {
  const ordersCount = santa.ordersCount;
  const ordersValue = santa.ordersValue;
  const santaPayout = santa.payout;

  return (
    <div className="w-full px-3 lg:px-4 flex-col  mt-2">
      <div className="flex items-center justify-between w-full ">
        <div className="  text-2xs px-2 py-0.5 bg-blue-100 rounded text-[#2136d4]">
          This week : week {moment().isoWeek()}
        </div>
      </div>
      <div className=" flex-wrap whitespace-nowrap gap-y-3 gap-x-6 w-full rounded-lg mt-2 flex items-center ">
        {[
          {
            label: "Orders",
            value: ordersCount,
          },
          {
            label: "Revenue",
            value: formatCurrency(ordersValue),
          },
          {
            label: "Payout",
            value: formatCurrency(santaPayout),
          },
        ].map((curr, idx) => {
          return (
            <div className="flex-col flex-1 gap-1 pr-4 pl-1 " key={idx}>
              <div className={`text-2xs lg:text-xs text-gray-600 `}>
                {curr.label}
              </div>
              <div className={`flex gap-1.5 items-center text-sm lg:text-base`}>
                <div className="  font-bold  ">{curr.value}</div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const StatusMark = ({ santa }) => {
  const accountStatus = santa?.accountStatus?.status;
  const { onboardingStatus, isOnboardingComplete, pageCompleted } =
    santa?.profileDraftDetails || {};

  // onboarding is in progress
  if (!isOnboardingComplete) {
    return (
      <div className="flex-col text-[#2136d4] whitespace-nowrap text-[10px] font-medium md:text-xs lg:text-sm">
        <div className="  flex items-center gap-2 ">
          Onboarding
          <div className="custom-blue-pulse scale-50 md:scale-75"></div>
        </div>
        <div className="  ">{pageCompleted}/3 pages completed</div>
      </div>
    );
  }

  if (accountStatus === "blocked") {
    return (
      <div className="flex items-center text-red-600 whitespace-nowrap gap-1  text-[10px] font-medium md:text-xs lg:text-sm">
        Blocked
        <div className="red-gradient rounded-full flex-center size-4 md:size-6 text-[10px] md:text-sm ">
          <ImBlocked />
        </div>
      </div>
    );
  }

  if (onboardingStatus === "inReview") {
    return (
      <div className="flex items-center text-red-600 whitespace-nowrap gap-1  text-[10px] font-medium md:text-xs lg:text-sm">
        Onboard In-review
        <div className="orange-gradient rounded-full flex-center size-4 md:size-6 text-[10px] md:text-sm ">
          <MdOutlinePendingActions />
        </div>
      </div>
    );
  }

  if (onboardingStatus === "rejected") {
    return (
      <div className="flex items-center text-red-600 whitespace-nowrap gap-1  text-[10px] font-medium md:text-xs lg:text-sm">
        Onboard Rejected
        <div className="red-gradient rounded-full flex-center size-4 md:size-6 text-[10px] md:text-sm ">
          <MdErrorOutline />
        </div>
      </div>
    );
  }

  if (onboardingStatus === "approved" && accountStatus === "active") {
    return (
      <div className="green-gradient rounded-full flex-center size-5 text-sm ">
        <BiCheck />
      </div>
    );
  }

  return <RatingStars stars={santa.rating} />;
};

const RatingStars = ({ stars = 5 }) => {
  let starColor = "text-[#2136d4]";

  if (stars === 3) {
    starColor = "text-orange-600";
  }

  if (stars < 3) {
    starColor = "text-red-600";
  }

  if (stars === 0) return null;

  return (
    <div
      className={`flex ${starColor} items-center text-sm md:text-base gap-0.5 `}
    >
      {stars}
      <FaStar
        className={` animate__animated animate__tada animate__repeat-2 text-base lg:text-lg `}
      />
    </div>
  );
};

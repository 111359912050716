import { useEffect } from "react";
import { useStoreContext } from "../../context/StoreContext";
import { insideHubBoundary } from "../../functions/googlemap/availablityChecks";

let timeoutId = null;
const COUNTRY = "IN";
let autocompleteService = null;

export default function useGoogleMaps({
  DEBOUNCE_DELAY_TIME = 1500,
  minSearchTextLengthToStartSearch = 3,
  mapContainerRef,
}) {
  const { storeStates } = useStoreContext();

  useEffect(() => {
    if (storeStates.isGoogleMapsScriptLoaded) {
      autocompleteService = new window.google.maps.places.AutocompleteService();
    }
  }, [storeStates.isGoogleMapsScriptLoaded]);

  const debounce = (func) => {
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, DEBOUNCE_DELAY_TIME);
    };
  };

  const handlePlacesSearch = (text, onSuccess, onError) => {
    try {
      if (!autocompleteService) {
        console.log("autocompleteService not loaded");
        timeoutId && clearTimeout(timeoutId);
        onError("autocompleteService not loaded");
        return;
      }

      if (text.length < minSearchTextLengthToStartSearch) {
        console.log("text length is less than minimum length");
        timeoutId && clearTimeout(timeoutId);
        return;
      }

      const handleSuggestions = (predictions, status) => {
        if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
          console.log("no predictions");
          onError("No predictions found");
          return;
        }
        onSuccess(predictions, status);
      };

      autocompleteService.getPlacePredictions(
        {
          input: text,
          origin: { lat: 0, lng: 0 },
          componentRestrictions: {
            country: COUNTRY,
          },
        },
        handleSuggestions
      );
    } catch (error) {
      console.error(error);
    }
  };

  const debouncedSearch = debounce(({ text, onSuccess, onError }) =>
    handlePlacesSearch(text, onSuccess, onError)
  );

  const reverseGeocode = ({
    location,
    onError = () => {
      window.toast("Geocoder failed", "error");
    },
    onSuccess = (results) => {
      console.log(results);
    },
  }) => {
    // location:   { lat: 12.9715987, lng: 77.5945627 }
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: location }, (results, status) => {
      if (status === "OK") {
        if (results[0]) {
          onSuccess(results);
        } else {
          window.toast("No results found", "error");
          return [];
        }
      } else {
        onError();
        console.error("Geocoder failed due to:", status);
      }
    });
  };

  const hubCheck = async (location) => {
    const isPointInPolygon = await insideHubBoundary([
      location.lng,
      location.lat,
    ]);

    if (isPointInPolygon.isInside) {
      const tempHub = storeStates?.allHubs.find(
        (hub) => hub._id === isPointInPolygon.foundInWhichHub
      );

      return {
        hubId: isPointInPolygon.foundInWhichHub,
        city: tempHub?.city,
        isInside: true,
      };

      // debouncedGeoCodeApi(location);
    } else {
      return { isInside: false };
    }
  };

  const getDetailsForPlaceID = async (placeId) => {
    try {
      const geocodingUrl = `https://maps.googleapis.com/maps/api/geocode/json?place_id=${placeId}&key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}`;

      const response = await fetch(geocodingUrl);
      const data = await response.json();

      if (data.status === "OK") {
        const location = data.results[0].geometry.location;
        return { data, lat: location.lat, lng: location.lng };
      } else {
        return { data: null, lat: null, lng: null };
      }
    } catch (error) {
      console.log(error);
      return { data: null, lat: null, lng: null };
    }
  };

  return {
    placeSearchByText: debouncedSearch,
    getDetailsForPlaceID,
    hubCheck,
    reverseGeocode,
  };
}

import React, { useMemo } from "react";
import AppleMapWithPolygons from "../../../assets/location/AppleMapWithPolygons";
import { useStoreContext } from "../../../context/StoreContext";
import {
  FaAngleLeft,
  FaCity,
  FaExpand,
  FaEye,
  FaEyeSlash,
} from "react-icons/fa6";
import useGoogleMaps from "../../../hooks/maps/useGoogleMaps";

const EmbededMapPresentation = ({ states, setStates }) => {
  const { reverseGeocode, hubCheck } = useGoogleMaps({});
  const {
    storeStates: {
      universalFilter = {
        city: "all",
        hub: "all",
      },
      allHubs,
      hubBoundaries,
    },
    setStoreStates,
  } = useStoreContext();

  const hubPolygonDetails = useMemo(() => {
    let coordinates = [];
    let hubDetails = [];

    for (const curr of hubBoundaries) {
      const tempHub = allHubs.find((hub) => hub._id === curr.properties.hubId);

      if (!tempHub) continue;

      const tempHubDetailObj = {
        hubId: curr.properties.hubId,
        bgColor: "#2136d4",
        borderColor: "#2136d4",
        hubInfo: tempHub,
      };

      if (universalFilter.city !== "all") {
        if (universalFilter.hub === "all") {
          if (tempHub.city === universalFilter.city) {
            hubDetails.push(tempHubDetailObj);
            coordinates.push(curr.geometry?.coordinates?.[0]);
          }
        } else {
          if (universalFilter.hub === curr.properties.hubId) {
            hubDetails.push(tempHubDetailObj);
            coordinates.push(curr.geometry?.coordinates?.[0]);
          }
        }
      } else {
        hubDetails.push(tempHubDetailObj);
        coordinates.push(curr.geometry?.coordinates?.[0]);
      }
    }
    return { coordinates: coordinates, hubDetails };
  }, [hubBoundaries, allHubs, universalFilter]);

  const points = useMemo(() => {
    let p = [...states.map.points];

    if (states.santasDetails.selectedSanta) {
      const santa = states.santasDetails.selectedSanta;
      const santaPoint = getPointForSanta({ santa, states, setStates });

      if (santaPoint) {
        p.push(santaPoint);
      }
    }

    if (states.placeSearch.lat) {
      p.push({
        lat: states.placeSearch.lat,
        lng: states.placeSearch.lng,
        santaId: null,
        title: "Selected Location",
        color: "#2136d4",
        text: "e",
      });
    }

    if (
      states.santasDetails.santas?.length > 0 &&
      states.map.santasPresentation
    ) {
      for (const santa of states.santasDetails.santas) {
        const santaPoint = getPointForSanta({ santa, states, setStates });
        if (santaPoint) {
          p.push(santaPoint);
        }
      }
    }

    return p;

    // eslint-disable-next-line
  }, [
    states.santasDetails.selectedSanta,
    states.placeSearch.lat,
    states.map?.santasPresentation,
    states.santasDetails.santas,
  ]);

  const onMapClick = (point) => {
    setStates((p) => ({
      ...p,
      placeSearch: {
        ...p.placeSearch,
        pointDetailsContainerLoading: true,
      },
    }));

    reverseGeocode({
      location: { lat: point.lat, lng: point.lng },
      onSuccess: async (results) => {
        const hubCheckResponse = await hubCheck({
          lat: point.lat,
          lng: point.lng,
        });
        if (hubCheckResponse.hubId) {
          setStoreStates((p) => ({
            ...p,
            universalFilter: {
              ...p.universalFilter,
              city: hubCheckResponse.city,
              hub: hubCheckResponse.hubId,
            },
          }));
        } else {
          setStoreStates((p) => ({
            ...p,
            universalFilter: {
              ...p.universalFilter,
              city: "all",
              hub: "all",
            },
          }));
        }

        setStates((p) => ({
          ...p,
          placeSearch: {
            ...p.placeSearch,
            lat: point.lat,
            lng: point.lng,
            hub: hubCheckResponse.hubId,
            pointDetailsContainerLoading: false,
            address1: results[0].formatted_address.split(",")[0],
            address2: results[0].formatted_address,
          },
          map: {
            ...p.map,
            focusPoint: {
              lat: point.lat,
              lng: point.lng,
            },
          },
        }));
      },
      onError: () => {
        window.toast("Geocoder failed", "error");
        setStates((p) => ({
          ...p,
          placeSearch: {
            ...p.placeSearch,
            pointDetailsContainerLoading: false,
            lat: point.lat,
            lng: point.lng,
          },
          map: {
            ...p.map,
            focusPoint: {
              lat: point.lat,
              lng: point.lng,
            },
          },
        }));
      },
    });
  };

  return (
    <div
      className={` ${
        states.map.isMapExpanded
          ? " fix-screen z-[5]"
          : " w-full h-[25rem] rounded-lg"
      } bg-white flex-col relative`}
    >
      {states.map.isMapExpanded ? (
        <TopHeader {...{ setStates }} />
      ) : (
        <div
          onClick={() => {
            setStates((p) => ({
              ...p,
              map: {
                ...p.map,
                isMapExpanded: true,
              },
            }));
          }}
          className=" absolute top-3 left-3 cursor-pointer bg-white rounded-full border border-gray-200 z-[2] flex-center size-8 "
        >
          <FaExpand />
        </div>
      )}

      <div
        className={`absolute ${
          states.map.isMapExpanded
            ? "top-[4.5rem] left-3 "
            : "top-3 left-[3.5rem] "
        } flex-center gap-2 z-[2] `}
      >
        <div
          onClick={() => {
            setStates((p) => ({
              ...p,
              map: {
                ...p.map,
                hubBoundariesPresentation: !p.map.hubBoundariesPresentation,
              },
            }));
          }}
          className={`${
            states.map.hubBoundariesPresentation
              ? " blue-gradient "
              : " border border-gray-200 bg-white text-gray-500 "
          } cursor-pointer rounded-full flex-center py-1 gap-1 px-2 `}
        >
          <span className={`text-sm`}>Hubs</span>
          {states.map.hubBoundariesPresentation ? (
            <FaEye className="flex-shrink-0 text-base" />
          ) : (
            <FaEyeSlash className="flex-shrink-0 text-base" />
          )}
        </div>
        {states.santasDetails.santas?.length > 0 && (
          <div
            onClick={() => {
              setStates((p) => ({
                ...p,
                map: {
                  ...p.map,
                  santasPresentation: !p.map.santasPresentation,
                },
              }));
            }}
            className={`  ${
              states.map.santasPresentation
                ? " blue-gradient "
                : " border border-gray-200 bg-white text-gray-500 "
            } cursor-pointer rounded-full flex-center py-1 gap-1 px-2 `}
          >
            <span className={`text-sm`}>Santas</span>
            {states.map.santasPresentation ? (
              <FaEye className="flex-shrink-0 text-base" />
            ) : (
              <FaEyeSlash className="flex-shrink-0 text-base" />
            )}
          </div>
        )}
      </div>

      <PointInfoContainer {...{ states, setStates }} />

      <AppleMapWithPolygons
        points={points}
        focusPoint={states.map.focusPoint}
        shouldPolygonsPresent={states.map.hubBoundariesPresentation}
        polygons={[hubPolygonDetails]}
        onMapClick={onMapClick}
      />
    </div>
  );
};

const PointInfoContainer = ({ states, setStates, hub }) => {
  if (!states.placeSearch?.lat) {
    return null;
  }

  return (
    <div className="absolute -bottom-2 flex-col cursor-pointer max-w-full w-[30rem] -left-2 bg-white rounded-lg border items-start border-gray-200 z-[2] p-3 ">
      {states.placeSearch?.pointDetailsContainerLoading ? (
        <>
          <div className="mb-2 h-3 w-[10rem] rounded-full skeleton "></div>
          <div className=" h-3 w-[18rem] rounded-full skeleton "></div>
        </>
      ) : (
        <>
          <div className="flex items-center gap-2 mb-2">
            <div className="text-sm font-medium">
              {states.placeSearch?.address1 || "-"}
            </div>
            {states.placeSearch?.hub ? (
              <div className="text-xs text-[#2136d4] bg-blue-50 px-2 py-0.5 font-medium">
                {states.placeSearch?.hub}
              </div>
            ) : (
              <div className="text-xs text-red-600 bg-red-50 px-2 py-0.5 font-medium">
                Not in any hub
              </div>
            )}
          </div>
          <div className="text-xs text-gray-600 font-medium">
            {states.placeSearch?.address2 || "-"}
          </div>
        </>
      )}
    </div>
  );
};

export const TopHeader = ({ setStates }) => {
  const {
    storeStates: { universalFilter = {}, hubsByCity },
    setStoreStates,
  } = useStoreContext();

  return (
    <div className="flex items-center w-full flex-shrink-0 px-4 py-3 justify-between blue-gradient overflow-scroll whitespace-nowrap">
      <div
        onClick={() => {
          setStates((p) => ({
            ...p,
            map: {
              ...p.map,
              isMapExpanded: false,
            },
          }));
        }}
        className="flex items-center gap-2 text-sm lg:text-lg cursor-pointer font-semibold"
      >
        <FaAngleLeft className="" />
        <div className=" flex flex-col w-full">
          <div className=" font-[600] text-base lg:text-lg">Back</div>
        </div>
      </div>
      <div className="p-1 px-2  bg-blue-100 rounded-lg text-black text-xs md:text-sm flex items-center gap-2 font-semibold">
        <FaCity className="text-[#2136d4] text-lg" />
        <select
          value={universalFilter.city}
          onChange={(e) =>
            setStoreStates((p) => ({
              ...p,
              universalFilter: {
                ...p.universalFilter,
                city: e.target.value,
                hub: "all",
              },
            }))
          }
          className="p-1 px-2 md:px-6 capitalize bg-blue-50 rounded-lg text-xs md:text-sm border border-blue-300"
        >
          <option value="all">All</option>
          {hubsByCity?.map?.((currCity) => {
            return (
              <option value={currCity.city} key={currCity.city}>
                {currCity.city}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};

const getPointForSanta = ({ santa, states, setStates }) => {
  const isLocationavailable =
    states.santasDetails.santaPointType === "lastLogin"
      ? santa?.lastLogin?.location?.lat
      : santa?.address?.currentAddress?.lat;

  if (isLocationavailable) {
    return {
      lat:
        states.santasDetails.santaPointType === "lastLogin"
          ? santa?.lastLogin?.location?.lat
          : santa?.address?.currentAddress?.lat,
      lng:
        states.santasDetails.santaPointType === "lastLogin"
          ? santa?.lastLogin?.location?.lng
          : santa?.address?.currentAddress?.lng,
      santaId: santa._id,
      onPointClick: () => {
        setStates((p) => ({
          ...p,
          santasDetails: {
            ...p.santasDetails,
            selectedSanta: santa,
          },
        }));
      },
      title: santa?.personalDetails?.name?.split?.(" ")?.[0] || "",
      color: "#2136d4",
      text: "Santa",
    };
  }

  return null;
};

export default EmbededMapPresentation;

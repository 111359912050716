import React from "react";
import { useStoreContext } from "../../../context/StoreContext";
import useGoogleMaps from "../../../hooks/maps/useGoogleMaps";
import { RxCross2 } from "react-icons/rx";
import { FaLocationArrow } from "react-icons/fa";

const PlaceSearch = ({ states, setStates }) => {
  const { placeSearchByText, getDetailsForPlaceID, hubCheck } = useGoogleMaps(
    {}
  );
  const { setStoreStates } = useStoreContext();

  const skeletonCount = new Array(5).fill(0);

  const handlePredictionClick = async (prediction) => {
    window.loadingScreen(true, "Loading location details...");
    try {
      const data = await getDetailsForPlaceID(prediction.place_id);
      const hubCheckResponse = await hubCheck({
        lat: data.lat,
        lng: data.lng,
      });

      if (hubCheckResponse.hubId) {
        setStoreStates((p) => ({
          ...p,
          universalFilter: {
            ...p.universalFilter,
            city: hubCheckResponse.city,
            hub: hubCheckResponse.hubId,
          },
        }));
      } else {
        setStoreStates((p) => ({
          ...p,
          universalFilter: {
            ...p.universalFilter,
            city: "all",
            hub: "all",
          },
        }));
      }

      setStates((p) => ({
        ...p,
        placeSearch: {
          ...p.placeSearch,
          lat: data.lat,
          lng: data.lng,
          address1: prediction.description.split(",")[0],
          address2: prediction.description,
          hub: hubCheckResponse.hubId,
          selectedPrediction: prediction,
          predictions: null,
          isPredictionLoading: false,
        },
        map: {
          ...p.map,
          focusPoint: {
            lat: data.lat,
            lng: data.lng,
          },
        },
      }));
    } catch (error) {
      console.log(error);
    }
    window.loadingScreen(false);
  };

  return (
    <div className="flex-col w-full bg-white rounded-lg border relative border-gray-200 p-4">
      <div className="w-full text-gray-500 mb-2 font-medium text-base">
        Search for the location
      </div>
      <input
        type="text"
        value={states?.placeSearch?.placeSearchBarText}
        onChange={(e) => {
          if (e.target.value.length <= 3) {
            setStates((p) => ({
              ...p,
              placeSearch: {
                ...p.placeSearch,
                predictions: null,
                isPredictionLoading: false,
                placeSearchBarText: e.target.value,
              },
            }));
          } else {
            setStates((p) => ({
              ...p,
              placeSearch: {
                ...p.placeSearch,
                isPredictionLoading: true,
                placeSearchBarText: e.target.value,
              },
            }));
          }

          placeSearchByText({
            text: e.target.value,
            onSuccess: (predictions) => {
              setStates((p) => ({
                ...p,
                placeSearch: {
                  ...p.placeSearch,
                  predictions,
                  isPredictionLoading: false,
                },
              }));
            },
            onError: (error) => {
              window.toast(error, "error");
              setStates((p) => ({
                ...p,
                placeSearch: {
                  ...p.placeSearch,
                  predictions: null,
                  isPredictionLoading: false,
                },
              }));
            },
          });
        }}
        placeholder="Search for a place"
        className="w-full p-2 border text-sm border-gray-300 rounded-lg"
      />
      {(states.placeSearch?.predictions !== null ||
        states.placeSearch.isPredictionLoading) && (
        <div className="flex-col w-full border rounded-lg bg-white border-gray-200 p-4 pb-0 absolute right-0 z-[3] left-0 top-[6rem] ">
          <div className="flex items-center justify-between w-full">
            <div className=" uppercase text-gray-500 text-sm font-[500] ">
              Search result
            </div>
            <RxCross2
              className=" text-lg "
              onClick={() => {
                setStates((p) => ({
                  ...p,
                  placeSearch: {
                    ...p.placeSearch,
                    predictions: null,
                    isPredictionLoading: false,
                  },
                }));
              }}
            />
          </div>

          <div className="flex-col divide-y-2 divide-blue-100">
            {states.placeSearch.isPredictionLoading && (
              <>
                {skeletonCount.map((_, i) => {
                  return (
                    <div key={i} className="flex flex-col py-4">
                      <div className=" w-[5rem] h-[.875rem] rounded-md skeleton mb-2"></div>
                      <div className=" w-[10rem] h-[.875rem] rounded-md skeleton"></div>
                    </div>
                  );
                })}
              </>
            )}

            {states?.placeSearch?.predictions?.length > 0 && (
              <>
                {states?.placeSearch?.predictions?.map?.(
                  (currPrediction, i) => {
                    return (
                      <div
                        onClick={() => {
                          handlePredictionClick(currPrediction);
                        }}
                        key={i}
                        className="flex flex-col py-4 cursor-pointer"
                      >
                        <div className="flex items-center gap-2 text-sm text-[#555555] font-[500] mb-1">
                          <FaLocationArrow />
                          {currPrediction.description.split(",")[0]}
                        </div>
                        <div className="text-[gray] text-xs w-full  ">
                          {currPrediction.description}
                        </div>
                      </div>
                    );
                  }
                )}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default PlaceSearch;

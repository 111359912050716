import axios from "axios";

const BaseUrl = process.env.REACT_APP_API_URL;

export const v2Api = async (options) => {
  try {
    const token = localStorage.getItem("ACCESS_TOKEN") || options.token;

    const res = await axios.post(
      `${BaseUrl}/api/v2/`,
      {
        options: {
          ...options,
          token,
        },
      },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    return res.data;
  } catch (error) {
    console.error("Error logging user location:", error);
    return {
      isSuccess: false,
      isAuthenticated: error?.response?.data?.isAuthenticated,
      status: error?.response?.status,
      message: error,
    };
  }
};

export const downloadAudioFile = async (url) => {
  window.loadingScreen(true, "Downloading audio file...");
  try {
    const response = await axios({
      url, // The audio file URL
      method: "GET",
      responseType: "blob", // Ensure it's handled as a binary
    });

    // Create a link element to trigger the download
    const link = document.createElement("a");
    const urlBlob = window.URL.createObjectURL(new Blob([response.data]));
    link.href = urlBlob;
    link.setAttribute("download", "audiofile.mp3"); // File name for download
    document.body.appendChild(link);
    link.click();

    // Clean up after download
    link.parentNode.removeChild(link);
    window.URL.revokeObjectURL(urlBlob);
  } catch (error) {
    console.error("Error downloading the audio file:", error);
  }
  window.loadingScreen(false);
};

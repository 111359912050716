import React from "react";
import PlaceSearch from "./PlaceSearch";
import ProductSelectSection from "./ProductSelectSection";

export default function LeftSection({ states, setStates }) {
  return (
    <div className="p-4 w-full lg:w-[40%] bg-blue-50 space-y-4 flex-col ">
      <PlaceSearch {...{ states, setStates }} />
      <ProductSelectSection {...{ states, setStates }} />
    </div>
  );
}

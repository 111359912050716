import React from "react";
import SantaPresentationCont from "./SantaPresentationCont";
import EmbededMapPresentation from "./EmbededMapPresentation";

export default function RightSection({ states, setStates }) {
  return (
    <div className="p-4 w-full lg:w-[60%] bg-blue-100 space-y-4 flex-col ">
      <EmbededMapPresentation {...{ states, setStates }} />
      <SantaPresentationCont {...{ states, setStates }} />
    </div>
  );
}

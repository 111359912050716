import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import {
  convertPhoneNumberToCode,
  serviceableCities,
} from "../../../../utils/adminUtils";

let timeoutId;

export default function UserSelectionContainer({
  states,
  setStates,
  orderStates,
  setOrderStates,
}) {
  const [users, setUsers] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [showCreateUserModal, setShowCreateUserModal] = useState(false);
  const [creatingUser, setCreatingUser] = useState(false);

  // New user form state
  const [newUser, setNewUser] = useState({
    name: "",
    phoneNumber: "",
    city: "",
    gender: "",
  });

  // Fetch users using v2Crud
  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line
  }, []);

  const fetchUsers = (query = "") => {
    window.v2Crud({
      queries: [
        {
          modelName: "User",
          operation: "read",
          sort: { createdAt: -1 },
          limit: users === null ? 10 : 20,
          selects: ["name", "phoneNumber", "_id", "createdAt"],
          filter: query
            ? {
                $or: [
                  { name: { $regex: query, $options: "i" } },
                  { phoneNumber: { $regex: query, $options: "i" } },
                  { _id: { $regex: query, $options: "i" } },
                ],
              }
            : {},
        },
      ],
      onInitiate: () => setLoading(true),
      onSuccess: (res) => {
        setUsers(res?.data?.at(0)?.data || []);
        setLoading(false);
      },
      onError: (error) => {
        console.error("Error fetching users:", error);
        setLoading(false);
      },
    });
  };

  const debounce = (func, delay = 800) => {
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };
  const debouncedSearch = debounce((text) => fetchUsers(text));

  const onUserSelect = (user) => {
    setOrderStates((p) => ({ ...p, user, bookingAddress: null }));
    setStates((p) => ({ ...p, currentSection: 1 }));
  };

  // Handle new user form changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewUser((prev) => ({ ...prev, [name]: value }));
  };

  const handleCreateUser = () => {
    if (
      !newUser.name ||
      !newUser.phoneNumber ||
      !newUser.city ||
      !newUser.gender
    ) {
      window.toast("Please fill all fields.");
      return;
    }

    // Check if user with the same phoneNumber exists

    window.v2Crud({
      queries: [
        {
          modelName: "User",
          operation: "read",
          filter: {
            phoneNumber: newUser.phoneNumber,
          },
          limit: 1,
        },
      ],
      onInitiate: () => setCreatingUser(true),

      onSuccess: (res) => {
        const existingUser = res?.data?.at(0)?.data[0];

        if (existingUser) {
          window.toast("User with this phone number already exists.");
          setCreatingUser(false);
          return;
        }

        // If no existing user, proceed with creating a new user
        window.v2Crud({
          queries: [
            {
              modelName: "User",
              operation: "create",
              docs: {
                ...newUser,
                _id: convertPhoneNumberToCode(newUser.phoneNumber)?.code,
              },
            },
          ],
          onSuccess: (res) => {
            const createdUser = res?.data?.at(0)?.data;
            setOrderStates((prev) => ({
              ...prev,
              user: createdUser,
              bookingAddress: null,
            }));
            setShowCreateUserModal(false);
            setNewUser({ name: "", phoneNumber: "", city: "", gender: "" });
            setCreatingUser(false);
            setStates((p) => ({ ...p, currentSection: 1 }));
          },
          onError: (error) => {
            console.error("Error creating user:", error);
            setCreatingUser(false);
          },
        });
      },
      onError: (error) => {
        console.error("Error checking existing user:", error);
        setCreatingUser(false);
      },
    });
  };

  return (
    <div className="flex-col space-y-4 w-full">
      {/* Search Input and Create New User Button */}
      <div className="flex items-center gap-2">
        <input
          type="text"
          placeholder="Search by Name, Phone Number, or ID"
          className="w-full p-2 border border-gray-300 rounded-md"
          value={searchQuery}
          onChange={(e) => {
            setLoading(true);
            debouncedSearch(e.target.value);
            setSearchQuery(e.target.value);
          }}
        />
        <button
          onClick={() => setShowCreateUserModal(true)}
          className="flex items-center justify-center whitespace-nowrap cursor-pointer p-2 blue-gradient rounded-md"
        >
          <span className="ml-1">Create User</span>
        </button>
      </div>

      {/* User List or Loading Skeleton */}
      <div className="space-y-2">
        {loading
          ? Array(5)
              .fill(0)
              .map((_, index) => (
                <div
                  key={index}
                  className=" w-full h-[4rem] rounded-lg skeleton"
                ></div>
              ))
          : users?.map?.((user) => (
              <div
                key={user._id}
                className="flex items-center p-3 bg-white rounded-lg shadow-sm hover:bg-gray-100 cursor-pointer"
                onClick={() => onUserSelect(user)}
              >
                <div className="w-10 h-10 flex items-center justify-center rounded-full bg-blue-500 text-white font-semibold">
                  {user.name ? user.name.charAt(0).toUpperCase() : "?"}
                </div>
                <div className="ml-4 flex-1">
                  <p className="font-semibold">{user.name || "Unnamed User"}</p>
                  <p className="text-gray-600 text-sm">
                    {user.phoneNumber || "No Phone Number"} ·{" "}
                    {user.createdAt
                      ? format(new Date(user.createdAt), "MMM d, yyyy h:mm a")
                      : "Unknown Date"}
                  </p>
                </div>
              </div>
            ))}
      </div>

      {/* Create User Modal */}
      {showCreateUserModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10">
          <div className="bg-white p-6 rounded-lg shadow-md w-[90%] max-w-md relative">
            <h3 className="text-lg font-semibold mb-4">Create New User</h3>
            <div className="space-y-4">
              <input
                type="text"
                name="name"
                placeholder="Name"
                value={newUser.name}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded-md"
              />
              <input
                type="text"
                name="phoneNumber"
                placeholder="Phone Number"
                value={newUser.phoneNumber}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded-md"
              />
              <select
                name="city"
                value={newUser.city}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded-md"
              >
                <option value="">Select City</option>
                {serviceableCities.map((city, index) => (
                  <option key={index} value={city}>
                    {city}
                  </option>
                ))}
              </select>
              <select
                name="gender"
                value={newUser.gender}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded-md"
              >
                <option value="">Select Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>
            </div>

            <div className="flex justify-end gap-4 mt-6">
              <button
                onClick={() => setShowCreateUserModal(false)}
                className="px-4 py-2 rounded-md bg-gray-200 text-gray-700"
              >
                Cancel
              </button>
              <button
                onClick={handleCreateUser}
                disabled={creatingUser}
                className={`px-4 py-2 rounded-md blue-gradient ${
                  creatingUser ? "opacity-50 cursor-not-allowed" : ""
                }`}
              >
                {creatingUser ? "Creating..." : "Create User"}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
